<template>
	<app-layout size="small">
		<div slot="header">
			<h5 style="font-size: 14px; text-align: center; margin: 0px auto 10px; color: #a1a1a1">{{ companyName }}</h5>
			<h4 style="font-size: 16px; text-align: center; color: #606266">{{ scheduleName }}</h4>
		</div>
		<div style="background: #f8f8f8; overflow: hidden; height: 100%">
			<el-checkbox-group v-model="selected">
				<list :finished="finished" :immediate-check="true" :loading="loading" finished-text="没有更多了" @load="getEvaluations">
					<el-row type="flex" class="employee-card" v-for="(item, i) in list" :key="i">
						<el-col :span="22">
							<div @click="view(item)">
								<p>
									<span>{{ item.userName }} </span><span class="job-num">({{ item.jobNumber }})</span>
								</p>
								<p class="row-middle">
									<span>得分:{{ item.score }}分</span> <span>职级代码:{{ item.levelAndCode }}</span>
									<span>职等:{{ item.jobEtc }}</span>
								</p>
								<p style="font-size: 12px">{{ item.updateTime }}</p>
							</div>
						</el-col>
						<el-col :span="2">
							<el-checkbox :label="item.id"></el-checkbox>
						</el-col>
					</el-row>
				</list>
			</el-checkbox-group>
		</div>
		<div slot="footer" style="text-align: center">
			<el-button type="primary" @click="selectAll" style="width: 30%">{{ selected.length === 0 ? '全选' : '全不选' }}</el-button>
			<el-button type="primary" @click="batchJudge" :disabled="selected.length === 0" style="width: 30%">一键复评</el-button>
		</div>
	</app-layout>
</template>
<script>
import { List } from 'vant';
import 'vant/lib/list/style';
import 'vant/lib/dialog/style';
import { loginAndGetEvaluations, batchJudge } from '../../api/wap';
import { Dialog, Notify } from 'vant';
export default {
	components: { List },
	data() {
		return {
			list: [],
			selected: [],
			apiParams: {
				pageNo: 1,
				pageSize: 40
			},
			params: {},
			companyName: '',
			scheduleName: '',
			customerComId: '',
			finished: false,
			loading: false,
			loginQuery: {}
		};
	},
	created() {
		if (!sessionStorage.getItem('againJudgeWapLoginInfo')) {
			this.$router.replace('/wap/login/again');
		}
		const params = JSON.parse(sessionStorage.getItem('againJudgeWapLoginInfo'));

		this.params.userName = params.userName;
		this.params.jobNumber = params.jobNumber;
		if (sessionStorage.getItem('wapLoginQuery')) {
			this.loginQuery = JSON.parse(sessionStorage.getItem('wapLoginQuery'));
		} else {
			this.$router.replace('/wap/login/again');
		}
		this.customerComId = sessionStorage.getItem('customerComId');
	},
	methods: {
		view(item) {
			this.$router.push({
				path: '/wap/judge/again',
				query: {
					id: item.id,
					staffResultId: item.staffResultId
				}
			});
		},
		selectAll() {
			if (this.selected.length === 0) {
				this.$set(
					this,
					'selected',
					this.list.map((v) => v.id)
				);
			} else {
				this.selected = [];
			}
		},
		batchJudge() {
			Dialog.confirm({
				title: '提示',
				message: '是否使用上一个评价人的评价结果作为这次复评结果？',
				showCancelButton: true
			}).then(() => {
				this.pageLoading = true;
				batchJudge({ assessorResultIds: this.selected, ...this.params, customerComId: this.customerComId }).then((res) => {
					this.pageLoading = false;
					if (res._responseStatusCode === 0) {
						Notify({ message: '复评成功!', type: 'success' });
						this.finished = false;
						this.apiParams.pageNo = 1;
						this.getEvaluations();
					}
				});
			});
		},
		getEvaluations() {
			if (this.finished === true) {
				return;
			}
			this.loading = true;
			loginAndGetEvaluations({ ...this.params, ...this.loginQuery })
				.then((res) => {
					this.loading = false;
					if (res._responseStatusCode === 0) {
						this.finished = false;
						this.companyName = res.companyName;
						this.scheduleName = res.scheduleName;
						if (res.list && res.list.length > 0) {
							res.list.forEach((v) => {
								this.list.push(v);
							});
							if (this.apiParams.pageNo >= res.totalPage) {
								this.finished = true;
								return;
							}
							this.apiParams.pageNo++;
						} else {
							this.list = [];
							this.finished = true;
						}
					} else {
						this.finished = true;
					}
				})
				.catch(() => {
					this.finished = true;
				});
		}
	}
};
</script>
<style lang="scss">
.employee-card {
	background: #fff;
	margin: 8px;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	border-radius: 4px;
	//   border-bottom: 1px solid;
	box-shadow: -3px 3px 3px #eaeaea;
	.el-col:nth-of-type(2) {
		display: flex;
		align-items: center;
	}
	.row-middle {
		span {
			padding-right: 14px;
		}
	}
	.el-checkbox__label {
		display: none;
	}
	.job-num {
		color: #a1a1a1;
		font-size: 12px;
		padding: 0 12px;
	}
	p {
		font-size: 14px;
		color: #606266;
		height: 20px;
	}
	.el-col {
		height: 50px;
	}
}
</style>
